.site-layout {
  min-height: 100vh;
  .site-main {
    background: var(--white);
    .site-main-background {
      .ant-menu-vertical {
        border: none;
      }
      .user-menu {
        .ant-menu-submenu-title {
          display: flex;
          margin: 0;
          padding: 0;
          align-items: center;
          border: none;
        }
        .ant-menu-submenu-arrow {
          display: none;
        }
      }
    }
    .page-title-container {
      margin: 35px;
      margin-bottom: 0px;
      .page-title {
        color: var(--black);
        font-size: 36px;
        line-height: 42px;
        font-weight: 500;
        margin-right: 14px;
      }
      .page-title-line {
        width: 90px;
        height: 0px;
        border: 3px solid #e0e0e0;
        border-radius: 10px;
        vertical-align: baseline;
      }

      @media (max-width: 911px) {
        margin-bottom: 0px;
        .page-title {
          font-size: 26px;
          margin: 0;
          text-align: center;
          width: 100%;
        }
        .page-title-line {
          display: none;
        }
      }
    }
  }
  .site-sider {
    .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
      background-color: unset !important;
    }

    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
      background: var(--very-light-green) !important;
      &::after {
        display: none;
      }
    }

    .submenu {
      .submenu-circle {
        width: 6px;
        height: 6px;
        border-radius: 100%;
        margin-right: 0.5rem;
      }
    }
    .submenu-active {
      .submenu-circle {
        background-color: var(--primary-color);
      }
      .submenu-title {
        color: var(--primary-color);
      }
    }

    .menu-active {
      color: var(--primary-color);
    }
    .menu-title {
      font-weight: 500;
      color: var(--mid-gray);
    }
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  }

  .header {
    .header-bar {
      padding: 0 20px;
      width: 100%;
      height: 90px;
    }
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  }
  .footer {
    background: var(--background-color);
    min-height: 200px;

    &__topline {
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      margin: 0 -15px;
    }

    &__content__container {
      margin: 1rem auto;
      font-size: 12px;
      line-height: 14px;
      font-weight: 400;

      .description__group {
        img {
          max-height: 25px;
          object-fit: contain;
        }
        margin-top: 10px;
        display: flex;
        gap: 1rem;
        padding: 0.5rem 0;
        span {
          align-self: center;
        }
      }
    }

    .site-brandname {
      color: var(--mid-gray);
    }
  }
}

.hidden-header {
  .header {
    display: none;
  }
}

.site-brand {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4px 0;
  margin-bottom: 1.5rem;
  .site-logo {
    margin-right: 0.5rem;
    margin-top: 35px;
    margin-bottom: 20px;
    width: 100px;
    height: 100px;
  }
  .site-name {
    text-transform: uppercase;
    color: black;
  }
  h1 {
    color: rgba(255, 255, 255, 0.65);
    margin: auto 0;
    animation: fadeRightIn 0.3s ease-in-out;
  }
}

@keyframes fadeRightIn {
  0% {
    transform: translateX(-5px);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@primary-color: #00933B;