.action-image-container {
  position: relative;
  cursor: pointer;
  &:hover {
    &::after {
      content: " ";
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      position: absolute;
      z-index: 1;
      background-color: rgba(0, 0, 0, 0.5);
    }
    .actions {
      display: flex;
    }
  }
  .actions {
    z-index: 2;
    display: grid;
    justify-content: center;
    align-items: center;
    gap: 10px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: none;
  }
  .action-image-date {
    position: absolute;
    z-index: 2;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    color: white;
    text-align: center;
    font-weight: 500;
    font-size: 15px;
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 5px;
  }
}

@primary-color: #00933B;