// TextField Textonly
.pure-material-textonly-outlined {
  position: relative;
  display: inline-block;
  padding-top: 10px;
  font-size: 14px;
  line-height: 1.5;
  cursor: default;
  .textonly-text {
    padding: 1rem;
    border: 1px solid var(--primary-color);
    border-top-color: transparent;
    background-color: transparent;
    border-radius: 8px;
    font-size: 12px;
  }
  .textonly-label {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    font-size: 14px;
    color: var(--primary-color);
    font-weight: 700;
  }
  .textonly-label::before {
    margin-right: 8px;
    border-left: solid 1px transparent;
    border-radius: 8px 0;
    content: "";
    display: block;
    box-sizing: border-box;
    margin-top: 6px;
    border-top: solid 1px;
    border-top-color: var(--border);
    min-width: 10px;
    height: 8px;
    pointer-events: none;
    box-shadow: inset 0 1px transparent;
  }
  .textonly-label::after {
    flex-grow: 1;
    margin-left: 8px;
    border-right: solid 1px transparent;
    border-radius: 0 8px;
    content: "";
    display: block;
    box-sizing: border-box;
    margin-top: 6px;
    border-top: solid 1px;
    border-top-color: var(--border);
    min-width: 10px;
    height: 8px;
    pointer-events: none;
    box-shadow: inset 0 1px transparent;
  }
}
// TextField Input
.pure-material-textfield-outlined {
  --pure-material-primary-rgb: #fff;
  --pure-material-safari-helper1: var(--border);
  position: relative;
  display: inline-block;
  padding-top: 6px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  // overflow: hidden;
  width: 100%;
  span {
    color: var(--primary-color) !important;
  }
  .ant-input:focus,
  .ant-input:hover {
    box-shadow: none;
    border-right-color: var(--border);
    border-left-color: var(--border);
    border-bottom-color: var(--border);
  }
}

/* Input, Textarea */
.pure-material-textfield-outlined {
  .ant-input:placeholder-shown {
    border-right-color: var(--border);
    border-left-color: var(--border);
    border-bottom-color: var(--border);
  }
}
.pure-material-textfield-outlined > input,
.pure-material-textfield-outlined > textarea {
  box-sizing: border-box;
  margin: 0;
  // border: solid 1px; /* Safari */
  // border-color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.6);
  border-top-color: transparent;
  border-radius: 8px;
  padding: 0.5rem;
  width: 100%;
  height: inherit;
  // color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.87);
  background-color: transparent;
  box-shadow: none; /* Firefox */
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  // caret-color: rgb(var(--pure-material-primary-rgb, 33, 150, 243));
  transition: border 0.2s, box-shadow 0.2s;
}

/* Span */
.pure-material-textfield-outlined > input + span,
.pure-material-textfield-outlined > textarea + span {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  // border-color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.6);
  width: 100%;
  max-height: 100%;
  // color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.6);
  font-size: 12px;
  line-height: 15px;
  cursor: text;
  transition: color 0.2s, font-size 0.2s, line-height 0.2s;
}

/* Corners */
.pure-material-textfield-outlined > input + span::before,
.pure-material-textfield-outlined > input + span::after,
.pure-material-textfield-outlined > textarea + span::before,
.pure-material-textfield-outlined > textarea + span::after {
  content: "";
  display: block;
  box-sizing: border-box;
  margin-top: 6px;
  border-top: solid 1px;
  // border-top-color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.6);
  border-top-color: var(--border);
  min-width: 10px;
  height: 8px;
  pointer-events: none;
  box-shadow: inset 0 1px transparent;
  // transition: border-color 0.2s, box-shadow 0.2s;
}

.pure-material-textfield-outlined > input + span::before,
.pure-material-textfield-outlined > textarea + span::before {
  margin-right: 8px;
  border-left: solid 1px transparent;
  border-radius: 8px 0;
}

.pure-material-textfield-outlined > input + span::after,
.pure-material-textfield-outlined > textarea + span::after {
  flex-grow: 1;
  margin-left: 8px;
  border-right: solid 1px transparent;
  border-radius: 0 8px;
}

/* Hover */
.pure-material-textfield-outlined:hover > input,
.pure-material-textfield-outlined:hover > textarea {
  // border-color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.87);
  border-top-color: transparent;
}

.pure-material-textfield-outlined:hover > input + span::before,
.pure-material-textfield-outlined:hover > textarea + span::before,
.pure-material-textfield-outlined:hover > input + span::after,
.pure-material-textfield-outlined:hover > textarea + span::after {
  // border-top-color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.87);
  border-top-color: var(--border);
}

/* Placeholder-shown */
.pure-material-textfield-outlined > input:not(:focus):placeholder-shown,
.pure-material-textfield-outlined > textarea:not(:focus):placeholder-shown {
  border-top-color: var(--border);
}

.pure-material-textfield-outlined > input:not(:focus):placeholder-shown + span,
.pure-material-textfield-outlined > textarea:not(:focus):placeholder-shown + span {
  font-size: 12px;
  line-height: 56px;
}

.pure-material-textfield-outlined > input:not(:focus):placeholder-shown + span::before,
.pure-material-textfield-outlined > textarea:not(:focus):placeholder-shown + span::before,
.pure-material-textfield-outlined > input:not(:focus):placeholder-shown + span::after,
.pure-material-textfield-outlined > textarea:not(:focus):placeholder-shown + span::after {
  border-top-color: transparent;
}

/* Focus */
.pure-material-textfield-outlined > input:focus,
.pure-material-textfield-outlined > textarea:focus {
  // border-color: rgb(var(--pure-material-primary-rgb, 33, 150, 243));
  border-top-color: transparent;
  // box-shadow: inset 1px 0 var(--pure-material-safari-helper1),
  //   inset -1px 0 var(--pure-material-safari-helper1),
  //   inset 0 -1px var(--pure-material-safari-helper1);
  outline: none;
}

.pure-material-textfield-outlined > input:focus + span::before,
.pure-material-textfield-outlined > input:focus + span::after,
.pure-material-textfield-outlined > textarea:focus + span::before,
.pure-material-textfield-outlined > textarea:focus + span::after {
  border-top-color: var(--pure-material-safari-helper1) !important;
  // box-shadow: inset 0 1px var(--pure-material-safari-helper1);
}

/* Disabled */
.pure-material-textfield-outlined > input:disabled,
.pure-material-textfield-outlined > input:disabled + span,
.pure-material-textfield-outlined > textarea:disabled,
.pure-material-textfield-outlined > textarea:disabled + span {
  // border-color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.38) !important;
  border-top-color: transparent !important;
  // color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.38);
  pointer-events: none;
}

.pure-material-textfield-outlined > input:disabled + span::before,
.pure-material-textfield-outlined > input:disabled + span::after,
.pure-material-textfield-outlined > textarea:disabled + span::before,
.pure-material-textfield-outlined > textarea:disabled + span::after {
  border-top-color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.38) !important;
}

.pure-material-textfield-outlined > input:disabled:placeholder-shown + span::before,
.pure-material-textfield-outlined > input:disabled:placeholder-shown + span::after,
.pure-material-textfield-outlined > textarea:disabled:placeholder-shown + span::before,
.pure-material-textfield-outlined > textarea:disabled:placeholder-shown + span::after {
  border-top-color: transparent !important;
}

/* Faster transition in Safari for less noticable fractional font-size issue */
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    .pure-material-textfield-outlined > input,
    .pure-material-textfield-outlined > input + span,
    .pure-material-textfield-outlined > textarea,
    .pure-material-textfield-outlined > textarea + span,
    .pure-material-textfield-outlined > input + span::before,
    .pure-material-textfield-outlined > input + span::after,
    .pure-material-textfield-outlined > textarea + span::before,
    .pure-material-textfield-outlined > textarea + span::after {
      transition-duration: 0.1s;
    }
  }
}

@primary-color: #00933B;