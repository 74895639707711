.language-menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 8px;
  transition: all 0.3s;
  span {
    font-weight: 700;
    font-size: 14px;
  }
}
.language-btn {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: none;
  width: 9rem;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
  height: 2.5rem;
  border-radius: 8px;
  transition: all 0.3s;
  span {
    font-weight: 500;
    font-size: 14px;
  }

  // &:hover .language-indicator {
  //   transform: rotate(-180deg);
  //   transition: transform 0.6s;
  // }
}

@primary-color: #00933B;