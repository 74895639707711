.transfer-table-container {
  width: 100%;
  border: 1px solid var(--border);
  border-radius: 4px;
  .table-head {
    width: 100%;
    background: #f5f5f5;
    height: 50px;
    font-weight: 500;
    color: var(--black);
    &__column {
      display: flex;
      align-items: center;
      padding: 0 24px;
      border-right: 1px solid var(--border);
      border-bottom: 1px solid var(--border);
      &:last-child {
        border-right: none;
      }
    }
  }
  .table-body {
    width: 100%;
    min-height: 200px;
    &__column {
      border-right: 1px solid var(--border);
      max-height: 500px;
      overflow: scroll;
      .search-row {
        width: 100%;
        padding: 16px;
        margin-left: 0 !important;
      }
      .function-row {
        width: 100%;
        user-select: none;
        .function-item {
          padding: 8px 24px;
          border-top: 1px solid var(--border);
          .transfer-button {
            padding: 2px 8px;
          }
          cursor: pointer;
          &:hover {
            background: var(--very-light-green);
          }
        }
      }
      &:last-child {
        border-right: none;
      }
    }
    &__pagination {
      margin: 16px 0;
    }
  }
}

@primary-color: #00933B;