.login-header-wrapper {
  background-color: var(--primary-color);
  .error-text {
    color: #fff !important;
  }
  .login-form {
    display: flex;
  }
  .login-header {
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .logo-wrapper {
    display: flex;
    .link-news {
      margin-left: 1rem;
      display: grid;
      place-items: center;
      color: #fff;
      font-weight: 700;
      font-size: 14px;
      a {
        padding: 0.5rem;
        color: var(--white);
      }
    }
    .link-news a:hover {
      font-weight: 700;
      font-size: 14px;
      background-color: rgba(255, 255, 255, 0.15);
      border-radius: 5px;
    }
  }
  .login-section.show {
    display: flex;
    flex-direction: column;
    .form-wrapper,
    .login-form {
      width: 100%;
    }
  }
  .login-section.hide {
    display: none;
  }
}

.form-group {
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
  justify-content: center;
  color: #fff;
  input {
    padding: 0 0.25rem;
    font-size: 12px;
    margin: 0.25rem 0;
  }
  .remember-wrapper {
    font-size: 12px;
    color: #fff;
    .ant-checkbox-inner {
      border-color: #fff;
      &::after {
        border-color: #fff;
      }
    }
  }
  a {
    margin-top: 2px;
    font-size: 12px;
    color: #fff;
    &:hover {
      text-decoration: none;
    }
  }
  .login-btn {
    padding: 0 0.25rem;
    height: auto;
    font-size: 12px;
    background-color: var(--primary-color);
    color: #fff;
    border-color: #fff;
    font-weight: bold;
  }
  .title {
    font-size: 12px;
    color: #fff;
    font-weight: 600;
  }
  .ant-form-item {
    margin-bottom: 0 !important;
    .ant-form-item-label {
      padding: 0;
    }
  }
  .form-item {
    margin-right: 0.5rem;
  }
}

.login-btn-wrapper {
  justify-content: flex-start;
  align-items: baseline;
  .error-text {
    margin-bottom: 0.25rem;
    color: #fcfff7;
    font-weight: 700;
  }
}

@media (max-width: 576.98px) {
  .login-header-wrapper {
    .logo-wrapper {
      .link-news {
        margin-left: 0;
        padding: 0.2rem;
        a {
          padding: 0.2rem;
        }
      }
    }
  }
}

@media (min-width: 880px) {
  .responsive-login-wrapper {
    display: none;
  }
}

@media (max-width: 879.98px) {
  .login-header-wrapper {
    .login-header {
      flex-wrap: wrap;
      .logo-wrapper {
        flex: 1 1 80%;
        justify-content: flex-start;
      }
      .responsive-login-wrapper {
        flex: 1 1 20%;
        place-items: center;
        display: grid;
        place-items: center;
        .responsive-login-button {
          text-align: center;
          display: block;
          color: white;
          padding: 0.25rem;
          color: #fff;
          font-weight: 700;
          font-size: 14px;
          cursor: pointer;
        }
        .responsive-login-button:hover {
          color: #fff;
          font-weight: 700;
          font-size: 14px;
          background-color: rgba(255, 255, 255, 0.15);
          border-radius: 5px;
        }
      }
      .login-section {
        flex: 1 1 100%;
        width: 100%;
        .login-form {
          flex-direction: column;
        }
      }
    }
  }
}

@primary-color: #00933B;