.footer {
  background: var(--white);
  a:hover {
    color: var(--primary-color);
  }
  .footer__topline {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }
  .footer__logo {
    margin-bottom: 1.875rem;
  }
  .footer__content {
    margin-top: 1.875rem;
    margin-bottom: 1.875rem;
    .footer__content__description {
      font-size: 12px;
      line-height: 16px;
      font-weight: 400;

      .description__group {
        a {
          color: var(--black);
          &:hover {
            color: var(--primary-color);
          }
        }
        img {
          max-height: 16px;
          object-fit: contain;
        }
        margin-top: 10px;
        display: flex;
        gap: 0.5rem;
        padding: 0.5rem 0;
        span {
          align-self: center;
        }
      }

      .logo {
        justify-content: center;

        img {
          max-height: 75px;
          margin-top: -10px;

          &:hover {
            opacity: 0.95;
          }
        }
      }
    }
    .footer__content__information__title {
      font-size: 14px;
      line-height: 16px;
      font-weight: bold;
      margin-bottom: 0.75rem;
    }
    .footer__copyright {
      padding-left: 2.5rem;
      color: #828282;
      font-size: 13px;
      line-height: 14px;
    }
  }
}

@primary-color: #00933B;